* {
    margin: 0;
    padding: 0;
}

.App {
    min-height: 100vh;
    /*background-image: url("../assets/images/Sun-Tornado.svg");*/
    /*background-repeat: no-repeat;*/
    /*background-position: center;*/
    /*background-size: cover;*/
}

.overlay {
    min-height: 100vh;
    background-color: rgba(252, 253, 253, 0.9);
}

p {
    margin: 0;
}

.text-link {
    color: inherit;
    text-decoration: inherit;
}

:root {
    font-size: 16px;
}

@media only screen and (min-width: 768px) {
    :root {
        font-size: 16px;
    }
}
